import { gsap } from 'gsap'

export default function scrollParallax (section) {
  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: section,
      start: 'top bottom',
      end: 'center center',
      scrub: 0.5
    }
  })
  timeline.fromTo(
    section,
    {
      opacity: 0
    },
    {
      duration: 0.3,
      ease: 'none',
      opacity: 1
    },
    0
  )
  return timeline
}
